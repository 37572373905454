.ui-steps {
    @media screen and (min-width: $breakpoint-sm) {
        &:before {
            top: 34px;
        }
    }

    .ui-steps-item {
        .ui-menuitem-link {
            @media screen and (min-width: $breakpoint-md) {
                .ui-steps-title {
                    display: block;
                }
            }
        }
    }

    .ui-steps-item {
        &.ui-state-disabled{
            opacity: 1;
            .ui-steps-number {
                color: $textColor;
            }
        }
    }
}


