.ui-fileupload {
    .ui-fileupload-buttonbar {
        background-color: $FDS-light-gray; // $panelHeaderBgColor - needed to lighten up the background since
                                           // our buttons are dark by default
        border: 1px solid $FDS-disabled-light_dark-background; // $panelHeaderBorder
        border-bottom: 0 none;
    }

    .ui-fileupload-content {
        background-color: $panelContentBgColor;
        border: 1px solid $FDS-disabled-light_dark-background; // $panelContentBorder
    }

    .ui-progressbar {
        top: -2px;
    }
}

.ui-fileupload-choose {
    &:not(.ui-state-disabled):hover {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
    }

    &:not(.ui-state-disabled):active {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
    }

    &.ui-state-focus {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
    }
}
