.ui-table {
    .ui-table-caption,
    .ui-table-summary {
        background-color: $white; // $panelHeaderBgColor
        align-items: center;
        flex-wrap: wrap;
        border: none;

        .ui-button{
            font-weight: 400;
        }
    }
}
