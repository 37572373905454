//adding display block to all block level tag so IE can work
article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section {
    display: block;
}

.p-grid {
    display: flex;
	margin: 0;
}

body {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;

    // adjust margin for font awesome icons only
    // adjusting margin on all i tags affects prime icons rendering in primeng components
    i.fas,
    i.far,
    i.fal,
    i.fad,
    i.fab {
        margin-right: 0.4em;
    }

	dl, h5 {
		margin: 0;
		padding: 0;
	}
	dd {
		margin-left: 0;
		word-wrap: break-word;
		padding: 0;
	}
	dt {
		font-family: $fontFamilyDefault;
		font-style: normal;
		font-weight: 700;
		font-size: 1em;
		padding: 0.75em 0 0;
	}
}
