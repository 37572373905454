/* horizontal form styling datepicker */
input[type="text"],
input[type="number"],
textarea,
select {
    width: 100%;
}

input[type="text"],
input[type="number"] {
    border: 1px solid $FDS-disabled-dark_light-background;
}
