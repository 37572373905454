.ui-steps {
    .ui-steps-item {
        .ui-menuitem-link {
            .ui-steps-number {
                top: 6px; // $fontSize + 2
            }

            .ui-steps-title {
                white-space: unset;
                margin-top: 0;
                display: none;
            }
        }
    }

    &:before {
        position: relative;
        top: 29px;
    }
}
