.ui-panelmenu {
    .ui-panelmenu-header {
        > a {
            .ui-panelmenu-icon {
                float: right;
            }
        }

        &:not(.ui-state-active) {
            > a:hover {
                background-color: $FDS-ford-blue;
            }
        }
    }

    .ui-panelmenu-panel {
        margin-top: 0; // $accordionSpacing
        &:first-child {
            margin-top: 40px;
        }
    }

    .ui-panelmenu-icon{
        float: right;
    }
}
