a{
    &.ui-button{
        box-shadow: inset 0 1em 1em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.2);

        &:hover{
            box-shadow: inset 0 1em 1em rgba(255,255,255,0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
            background-color: $buttonHoverBgColor;
            color: $buttonTextColor;
        }
    }
}

.ui-button-icon-only {
    .ui-button-icon-left {
        font-size: 1.25em;
    }
}

// select-all/select-none
.ui-button-link {
    margin: 0.0625em;
    text-decoration: underline;
    background: none !important;
    border: none;
    outline: none;
    cursor: pointer;
    color: $CTA-dark-cyan;
    font-family: $fontFamilyDefault;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
}

i {
    .button-icon {
        margin-left: -0.9em;
    }
}
