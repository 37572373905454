.ui-panelmenu {
	.ui-panelmenu-header {
		font-family: $fontFamilyFordAntenna;

		> a,
		.ui-state-active > a,
		:not(.ui-state-active) > a,
		:not(.ui-state-active) > a:hover {
			border-top: 1px solid $FDS-disabled-dark_light-background;
			border-bottom: 1px solid $FDS-disabled-dark_light-background;
			border-left: none !important;
			border-right: none !important;
		}
	}

	.ui-panelmenu-content {
		background-color: $white;
		border: none;
		font-family: $fontFamilyFordAntennaLight;

		.ui-menuitem {
			border-radius: 0;

			.ui-menuitem-link {
				color: $textColor;
				border-radius: 0;

				&.ui-menuitem-link-active {
					background-color: $primaryDarkColor;
					color: $primaryTextColor;
				}
			}
		}
	}
}
