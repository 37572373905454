.ui-menu {
    padding: $menuPadding;
    background-color: $menuBgColor;
    border: $menuBorder;

    .ui-menuitem-link {
        @include menuitem-link();
    }

    .ui-menuitem {
        margin: $menuitemMargin;
    }

    &.ui-shadow {
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);
    }

    .ui-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $menuitemPadding;
        color: $panelHeaderTextColor;
        background-color: $panelHeaderBgColor;
        font-weight: $panelHeaderFontWeight;
        border: 0 none;
    }

    .ui-menu-separator {
        border: $menuBorder;
        border-width: 1px 0 0 0;
    }
}
