form {
    border-radius: 5px;
    padding: .5em;
    box-shadow: 0 1px 10px #ccc;

    p-calendar {
        .ui-calendar {
            width: 100%;
			white-space: nowrap;

            input {
                width: 80%;
            }

            .ui-calendar-button {
                margin: 0 .125px 0 0;
            }
        }
    }

    p-dropdown {
        padding: 0;
    }
}

.ui-datepicker{
	table{
		td{
			> a, > span {
				&:hover{
					color: $primaryTextColor;
				}

				&.ui-state-disabled{
					&:hover{
						color: $textColor;
					}
				}
			}

			&.ui-datepicker-today{  // Edge case: needed for when the "today" date is near the end of the month (say the 30th),
				                    // and the user advances the calendar one month ahead, such that the "today" day is
				                    // still highlighted and can still be seen in calendar view, but now disabled,
				                    // and thus would take the wrong hover color from above without this rule
				> a, > span {
					&.ui-state-disabled {
						&:hover {
							color: $primaryTextColor;
						}
					}
				}
			}

		}
	}
}

