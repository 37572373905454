.ui-button {
    margin: 0;
    color: $buttonTextColor;
    background-color: $buttonBgColor;
    border: $buttonBorder;
    font-size: $fontSize;
    @include transition($buttonTransition);

    &:enabled:hover {
        background-color: $buttonHoverBgColor;
        color: $buttonHoverTextColor;
        border-color: $buttonHoverBorderColor;
    }

    &:enabled:focus {
        outline: $buttonFocusOutline;
        outline-offset: $buttonFocusOutlineOffset;
        @include shadow($buttonFocusShadow);
    }

    &:enabled:active  {
        background-color: $buttonActiveBgColor;
        color: $buttonActiveTextColor;
        border-color: $buttonActiveBorderColor;
    }

    &.ui-button-text-only {
        .ui-button-text {
            padding: $buttonTextOnlyPadding;
        }
    }

    &.ui-button-text-icon-left {
        .ui-button-text {
            padding: $buttonWithLeftIconPadding;
        }
    }

    &.ui-button-text-icon-right {
        .ui-button-text {
            padding: $buttonWithRightIconPadding;
        }
    }

    &.ui-button-icon-only {
        width: $buttonIconOnlyWidth;

        .ui-button-text {
            padding: $buttonIconOnlyPadding;
        }
    }

    &.ui-button-raised {
        @include shadow($raisedButtonShadow);
    }

    &.ui-button-rounded {
        @include border-radius($roundedButtonBorderRadius);
    }
}

.ui-fluid {
    .ui-button-icon-only {
        width: $buttonIconOnlyWidth;
    }
}

@media (max-width: 640px) {
    .ui-buttonset:not(.ui-splitbutton) {
        .ui-button {
            margin-bottom: 1px;
            @include border-radius($borderRadius);
        }
    }
}

.ui-splitbutton.ui-buttonset {
    .ui-button {
        border: 1px solid transparent;
    }

    .ui-menu {
        min-width: 100%;
    }
}

.ui-button.ui-state-default.ui-button-secondary, .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default  {
    color: $secondaryButtonTextColor;
    background-color: $secondaryButtonBgColor;
    border: $secondaryButtonBorder;

    &:enabled:hover {
        background-color: $secondaryButtonHoverBgColor;
        color: $secondaryButtonHoverTextColor;
        border-color: $secondaryButtonHoverBorderColor;
    }

    &:enabled:focus {
        @include shadow($secondaryButtonFocusShadow);
    }

    &:enabled:active {
        background-color: $secondaryButtonActiveBgColor;
        color: $secondaryButtonActiveTextColor;
        border-color: $secondaryButtonActiveBorderColor;
    }
}

.ui-button.ui-state-default.ui-button-info, .ui-buttonset.ui-button-info > .ui-button.ui-state-default  {
    color: $infoButtonTextColor;
    background-color: $infoButtonBgColor;
    border: $infoButtonBorder;

    &:enabled:hover {
        background-color: $infoButtonHoverBgColor;
        color: $infoButtonHoverTextColor;
        border-color: $infoButtonHoverBorderColor;
    }

    &:enabled:focus {
        @include shadow($infoButtonFocusShadow);
    }

    &:enabled:active {
        background-color: $infoButtonActiveBgColor;
        color: $infoButtonActiveTextColor;
        border-color: $infoButtonActiveBorderColor;
    }
}

.ui-button.ui-state-default.ui-button-success, .ui-buttonset.ui-button-success > .ui-button.ui-state-default  {
    color: $successButtonTextColor;
    background-color: $successButtonBgColor;
    border: $successButtonBorder;

    &:enabled:hover {
        background-color: $successButtonHoverBgColor;
        color: $successButtonHoverTextColor;
        border-color: $successButtonHoverBorderColor;
    }

    &:enabled:focus {
        @include shadow($successButtonFocusShadow);
    }

    &:enabled:active {
        background-color: $successButtonActiveBgColor;
        color: $successButtonActiveTextColor;
        border-color: $successButtonActiveBorderColor;
    }
}

.ui-button.ui-state-default.ui-button-warning, .ui-buttonset.ui-button-warning > .ui-button.ui-state-default  {
    color: $warningButtonTextColor;
    background-color: $warningButtonBgColor;
    border: $warningButtonBorder;

    &:enabled:hover {
        background-color: $warningButtonHoverBgColor;
        color: $warningButtonHoverTextColor;
        border-color: $warningButtonHoverBorderColor;
    }

    &:enabled:focus {
        @include shadow($warningButtonFocusShadow);
    }

    &:enabled:active {
        background-color: $warningButtonActiveBgColor;
        color: $warningButtonActiveTextColor;
        border-color: $warningButtonActiveBorderColor;
    }
}

.ui-button.ui-state-default.ui-button-danger, .ui-buttonset.ui-button-danger > .ui-button.ui-state-default  {
    color: $dangerButtonTextColor;
    background-color: $dangerButtonBgColor;
    border: $dangerButtonBorder;

    &:enabled:hover {
        background-color: $dangerButtonHoverBgColor;
        color: $dangerButtonHoverTextColor;
        border-color: $dangerButtonHoverBorderColor;
    }

    &:enabled:focus {
        @include shadow($dangerButtonFocusShadow);
    }

    &:enabled:active  {
        background-color: $dangerButtonActiveBgColor;
        color: $dangerButtonActiveTextColor;
        border-color: $dangerButtonActiveBorderColor;
    }
}
