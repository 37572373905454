.ui-datepicker {
    padding: $calendarPadding;
    min-width: $calendarWidth;
    background-color: $inputContentPanelBgColor;
    color: $inputContentPanelTextColor;
    border: $inputListBorder;

    &:not(.ui-datepicker-inline) {
        border: $inputOverlayBorder;
        @include shadow($inputOverlayShadow);
    }

    &:not(.ui-state-disabled) {
        .ui-datepicker-header {
            .ui-datepicker-prev,
            .ui-datepicker-next {
                &:hover {
                    color: $calendarNavIconHoverColor;
                }

                &:focus {
                    @include focused();
                }
            }
        }

        table {
            td {
                a:not(.ui-state-active):not(.ui-state-highlight):hover {
                    background-color: $calendarCellDateHoverBgColor;
                }
            }
        }

        .ui-monthpicker {
            a.ui-monthpicker-month:not(.ui-state-active):hover {
                background-color: $calendarCellDateHoverBgColor;
            }
        }
    }

    .ui-datepicker-header {
        padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
        background-color: $inputListHeaderBgColor;
        color: $inputListHeaderTextColor;
        @include border-radius(0);

        .ui-datepicker-prev,
        .ui-datepicker-next {
            cursor: pointer;
            top: 0;
            color: $calendarNavIconColor;
            @include transition($calendarNavIconTransition);
        }

        .ui-datepicker-title {
            margin: 0;
            padding: 0;
            line-height: 1;

            select {
                margin-top: -.35em;
                margin-bottom: 0;
                @include transition($calendarNavIconTransition);

                &:focus {
                    @include focused();
                }
            }
        }
    }

    table {
        font-size: $fontSize;
        margin: $calendarTableMargin;

        th {
            padding: $calendarHeaderCellPadding;

            &.ui-datepicker-weekheader {
                border-right: $inputListBorder;
            }
        }

        td {
            padding: $calendarCellDatePadding;

            > a, > span {
                display: block;
                text-align: center;
                color: $textColor;
                padding: $calendarCellDatePadding;
                @include transition(box-shadow $transitionDuration);
                @include border-radius($calendarCellDateBorderRadius);

                &.ui-state-active {
                    color: $calendarCellDateSelectedTextColor;
                    background-color: $calendarCellDateSelectedBgColor;
                }
            }

            > a {
                cursor: pointer;

                &:focus {
                    @include focused();
                }
            }

            &.ui-datepicker-today {
                > a, > span {
                    background-color: $calendarCellDateTodayBgColor;
                    color: $calendarCellDateTodayTextColor;

                    &.ui-state-active {
                        color: $calendarCellDateSelectedTextColor;
                        background-color: $calendarCellDateSelectedBgColor;
                    }
                }
            }

            &.ui-datepicker-weeknumber {
                border-right: $inputListBorder;
            }
        }
    }

    .ui-datepicker-buttonbar {
        border-top: $calendarButtonBarDivider;
    }

    .ui-timepicker {
        border: 0 none;
        border-top: $calendarTimePickerDivider;
        padding: $calendarTimePickerPadding;

        a {
            color:$calendarTimePickerIconColor;
            font-size: $calendarTimePickerIconFontSize;

            &:hover {
                color: $calendarTimePickerIconHoverColor;
            }
        }

        span {
            font-size: $calendarTimePickerTimeFontSize;
        }
    }

    .ui-monthpicker {
        .ui-monthpicker-month {
            color: $textColor;

            &.ui-state-active {
                color: $calendarCellDateSelectedTextColor;
                background-color: $calendarCellDateSelectedBgColor;
            }
        }
    }

    &.ui-datepicker-timeonly {
        padding: 0;

        .ui-timepicker {
            border-top: 0 none;
        }
    }

    &.ui-datepicker-multiple-month {
        .ui-datepicker-group {
            border-right: $calendarMultipleMonthDivider;
            padding-right: $calendarPadding;
            padding-left: $calendarPadding;
            padding-top: 0;
            padding-bottom: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: 0 none;
            }
        }
    }
}

.ui-calendar.ui-calendar-w-btn {
    .ui-inputtext {
        @include border-radius-right(0);
        border-right: 0 none;

        &:enabled:hover:not(.ui-state-error),
        &:enabled:focus:not(.ui-state-error) {
            border-right: 0 none;
        }
    }

    .ui-datepicker-trigger.ui-button {
        width: $buttonIconOnlyWidth;
        @include border-radius-left(0);
    }
}

.ui-fluid {
    .ui-calendar.ui-calendar-w-btn {
        input.ui-inputtext {
            width: calc(100% - #{$buttonIconOnlyWidth});
        }
    }
}

p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext {
    border: $inputErrorBorder;
}
