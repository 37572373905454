.ui-paginator {
    align-items: center;
    flex-wrap: wrap;

    .ui-paginator-first,
    .ui-paginator-prev,
    .ui-paginator-next,
    .ui-paginator-last {
        @include border-radius($paginatorElementBorderRadius);

        &:not(.ui-state-disabled):not(.ui-state-active):hover {
            box-shadow: inset 0 1em 1em rgba(255,255,255,0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
        }
    }

	.ui-dropdown {
		border: $inputBorder;

		&:hover {
			.ui-dropdown-trigger, .ui-dropdown-label {
				color: $textColor;
			}
		}
	}

    .ui-paginator-pages {
        // This number comes directly from PrimeNG, it is not random, it is used multiple times in the component.
        // For some reason the .ui-paginator-pages element doesn't include the height and this causes some problems with
        // the other overrides we have in place.
        height: 2.286em;

        .ui-paginator-page {
            @include border-radius($paginatorElementBorderRadius);

            &.ui-state-active{
                box-shadow: inset 0 1em 1em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.2);
            }

            &:not(.ui-state-active):hover{
                box-shadow: inset 0 1em 1em rgba(255,255,255,0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
            }
        }
    }
}
