p-autocomplete.ng-invalid.ng-touched > .ui-autocomplete > .ui-inputtext,
input.ng-dirty.ng-invalid.ng-touched,
input.ng-invalid.ng-touched,
input.ng-invalid.ng-touched.ui-inputtext,
input.ng-invalid.ng-touched.ui-inputtext:hover,
p-dropdown.ng-invalid.ng-touched .ui-dropdown,
p-dropdown.ng-invalid.ng-dirty .ui-dropdown,
p-calendar.ng-invalid.ng-touched .ui-calendar input,
p-calendar.ng-invalid.ng-touched .ui-calendar .ui-inputtext {
    border: $inputErrorBorder;
}
