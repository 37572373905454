.ui-paginator{
    &.ui-paginator-top {
        margin: 0.5em 0;
        align-items: center;

        .ui-paginator-right-content {
            display: flex;
            color: $textColor;
        }

        .ui-paginator-left-content {
            display: flex;
            color: $textColor;
        }
    }

    &.ui-paginator-bottom {
        margin: 0.5em 0;
        align-items: center;

        .ui-paginator-right-content {
            display: flex;
            color: $textColor;
        }

        .ui-paginator-left-content {
            display: flex;
            color: $textColor;
        }
    }

    .ui-paginator-right-content {
        margin-left: auto;
    }

    .ui-paginator-left-content {
        margin-right: auto;

        p-dropdown {
            margin: 0;
        }

        .resultsPerPageSpan {
            align-self: center;
            margin-right: .25em;
            font-weight: bold;
        }
    }

    @media screen and (max-width: 579px) {
        .ui-paginator-left-content {
            flex-basis: 100%;
        }

        a {
            &.ui-paginator-last {
                &.ui-paginator-element {
                    margin-right: auto;
                }
            }
        }
        .ui-paginator-left-content {
            .resultsPerPageDropdown {
                margin-right: auto;
            }
        }
        .resultsPerPageSpan {
            margin-left: .7em;
        }
    }

    @media screen and (max-width: 398px) {
        a {
            &.ui-paginator-first {
                &.ui-paginator-element {
                    margin-left: auto;
                }
            }
        }

        .ui-paginator-right-content {
            margin-right: auto;
        }

        .resultsPerPageSpan {
            margin-left: auto;
        }
    }
}
