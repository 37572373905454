.ui-tieredmenu {
    padding: $menuPadding;
    background-color: $menuBgColor;
    border: $menuBorder;

    .ui-menuitem-link {
        @include menuitem-link();
    }

    .ui-submenu-list {
        padding: $submenuPadding;
        background-color: $menuBgColor;
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);
    }

    .ui-menuitem {
        margin: $menuitemMargin;

        .ui-menuitem-link {
            .ui-submenu-icon {
                right: 0.429em;
            }
        }

        &.ui-menuitem-active {
            > .ui-menuitem-link {
                background-color: $menuitemActiveBgColor;

                .ui-menuitem-text {
                    color: $menuitemActiveTextColor;
                }

                .ui-menuitem-icon, .ui-submenu-icon {
                    color: $menuitemActiveIconColor;
                }
            }
        }
    }

    .ui-menu-separator {
        border: $menuBorder;
        border-width: 1px 0 0 0;
    }
}
