header,
#app-header {
    padding: 0.5em 0 1em;
    width: 100%;
    flex: 0 0 auto;
	& .skip-link {
		background: $primaryDarkerColor;
		color: $primaryTextColor;
		left: calc(50% - 3.75rem);
		padding: 0.25rem;
		position: absolute;
		text-align: center;
		transform: translateY(-130%);
		transition: transform 0.3s;
		width: 7.5rem;
	}
	& .skip-link:focus {
		transform: translateY(0%);
	}
}

#app-branding {
    h1{
        font-family: $fontFamilyFordAntenna;
        font-style: normal;
        font-weight: 400;
    }
}

#app-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

#app-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $FDS-ford-blue;

    > img {
        transition: $app-header-width-transition;
        max-width: 75px;
    }

    @media screen and (min-width: $breakpoint-xs) {
        > img {
            max-width: 100px;
        }
    }

    @media screen and (min-width: $breakpoint-sm) {
        > img {
            max-width: 105px;
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        > img {
            max-width: 110px;
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        > img {
            max-width: 115px;
        }
    }

    @media screen and (min-width: $breakpoint-xl) {
        > img {
            max-width: 120px;
        }
    }
}

#app-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25em 0 0 0.5em;

    h1 {
        margin: 0;
        font-family: $fontFamilyFordAntennaLight;
        font-style: normal;
        font-weight: 300;
        font-size: 1.125rem;
    }

    @media screen and (min-width: $breakpoint-xs) {
        h1 {
            font-size: 1.25rem; /*20px; this is what it used to be before converting to rems*/
        }
    }

    @media screen and (min-width: $breakpoint-sm) {
        h1 {
            font-size: 1.375rem; //22px; this is what it used to be before converting to rems
        }
    }

    @media screen and (min-width: $breakpoint-md) {
        h1 {
            font-size: 1.5rem; //24px; this is what it used to be before converting to rems
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        h1 {
            font-size: 1.625rem; //26px; this is what it used to be before converting to rems
        }
    }

    @media screen and (min-width: $breakpoint-xl) {
        h1 {
            font-size: 1.75rem; //28px; this is what it used to be before converting to rems
        }
    }
}


app-nav {
    display: flex;
    align-items: center;

    p-menubar {
        width: 100%;
    }
}

@media screen and (min-width: $breakpoint-md) {
    app-nav {
        display: unset;
    }
}

@media screen and (min-width: $breakpoint-md) {
    #app-sidebar {
        display: none;
    }
}
