.ui-breadcrumb {
    background-color: $panelContentBgColor;
    border: $panelContentBorder;
    padding: $panelContentPadding;

    ul {
        li {
            .ui-menuitem-link {
                color: $panelContentTextColor;
                margin: 0;
                @include border-radius($borderRadius);
                @include transition(box-shadow $transitionDuration);

                &:focus {
                    @include focused();
                }
            }

            &.ui-breadcrumb-chevron {
                margin: 0 $inlineSpacing 0 $inlineSpacing;
                color: $textSecondaryColor;
            }

            &:first-child {
                a {
                    color: $textSecondaryColor;
                    margin: 0;
                }
            }

            .ui-menuitem-icon {
                color: $textSecondaryColor;
            }
        }
    }
}
