.ui-dataview {
    .ui-dataview-header {
        background-color: inherit; // $panelHeaderBgColor
        border: none; //$panelHeaderBorder;
    }

    .ui-dataview-content {
        border: none; // $panelContentBorder
        padding: 0;  // $panelContentPadding
    }
}
