/*
    Blue Oval Theme
    ---------------

    |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ IMPORTANT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|
    | The Blue Oval theme and webcore should NOT be edited by application teams.  |
    |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|

    The Blue Oval theme is based on Web and Mobile Enablement's (WaME)'s webcore, which in turn is based on PrimeNG's
    Prime Designer API.  This is the default theme for projects that do NOT have a need to customize the theming aspect
    of their application.

    Instead, for teams that do have a need to customize their theme, we offer both the App Theme Basic for simple theme
    changes, or the App Theme Advanced for a more modular structure for those teams that have a lot of customizations
    they would like to achieve.
*/

@import '../webcore/webcore-variables';
@import 'variables';
@import "../designer/components";
@import '../webcore/components';
@import 'components';
