.ui-picklist {
    .ui-picklist-buttons {
        button {
            font-size: $fontSize + 2;
        }
    }

    .ui-picklist-caption {
        background-color: $panelHeaderBgColor;
        color: $panelHeaderTextColor;
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;
        font-weight: $panelHeaderFontWeight;
        border-bottom: 0 none;
        @include border-radius-top($borderRadius);
    }

    .ui-picklist-filter-container {
        padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
        border: $panelHeaderBorder;
        color: $inputListHeaderTextColor;
        background-color: $inputListHeaderBgColor;
        margin: $inputListHeaderMargin;
        border-bottom: 0 none;

        input.ui-picklist-filter {
            width: 100%;
            padding-right: 2em;
            text-indent: 0;
        }

        .ui-picklist-filter-icon {
            top: 50%;
            margin-top: -.5em;
            left: auto;
            right: $inputListHeaderPaddingRight + .5em;
            color: $inputListHeaderSearchIconColor;
        }
    }

    .ui-picklist-buttons {
        padding: $panelContentPadding;
    }

    .ui-picklist-list {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $inputListPadding;

        .ui-picklist-item {
            padding: $inputListItemPadding;
            margin: $inputListItemMargin;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background-color: $inputListItemBgColor;
            @include transition($inputListItemTransition);

            &:not(.ui-state-highlight):hover {
                background-color: $inputListItemHoverBgColor;
                color: $inputListItemHoverTextColor;
            }

            &.ui-state-highlight {
                background-color: $inputListItemHighlightBgColor;
                color: $inputListItemHighlightTextColor;
            }

            &:focus {
                @include focused-listitem();
            }
        }

        .ui-picklist-droppoint-highlight {
            background-color: $highlightBgColor;
        }
    }
}

@media (max-width: 40em) {
    .ui-picklist.ui-picklist-responsive {
        .ui-picklist-buttons {
            padding: $panelContentPadding;
        }
    }
}
